import React from 'react';
// Material UI
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const varieties = [
  '',
  'Barton',
  'Bradley',
  'Criolla',
  'Fructos',
  'Germinada',
  'Majan',
  'Western',
  'Wichita',
];

export default function SelectVariety({ disabled, label, setValue, value }) {
  return (
    <Autocomplete
      disabled={disabled}
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      options={varieties || []}
      getOptionLabel={(option) => option || ''}
      style={{ width: '100%' }}
      renderInput={(params) => (
        <TextField {...params} label={label || 'Variedad'} variant="outlined" />
      )}
    />
  );
}
