import React from 'react';
import { useHistory } from 'react-router-dom';
// Ant Design
import { HomeOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
// Material UI
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
const { Title } = Typography;

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(2),
  },
}));
export default function BreadcrumbsComponent({ data, title }) {
  const classes = useStyles();
  let history = useHistory();
  const handleClick = (path) => {
    path && history.push(path);
  };
  return (
    <>
      <Title level={2}>{title}</Title>
      <Breadcrumbs className={classes.root} aria-label="breadcrumb">
        <Link color="inherit" onClick={() => handleClick('/')}>
          <HomeOutlined />
        </Link>
        {data.map(({ label, path }, i) => (
          <Link
            key={i}
            color={i + 1 === data.length ? 'primary' : 'inherit'}
            onClick={() => handleClick(path)}
          >
            {label}
          </Link>
        ))}
      </Breadcrumbs>
    </>
  );
}
