import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
// Ant Design
import { Button, Layout } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
// Components
import NavItems from './NavItems';

const { Header, Content, Footer, Sider } = Layout;

const useStyles = createUseStyles({
  root: {
    minHeight: '100vh',
    overflowX: 'hidden',
    width: '100vw',
  },
  container: {
    padding: '0 2rem',
    width: '100%',
  },
  content: {
    margin: '0 auto',
    maxWidth: '1024px',
    width: '100%',
  },
  header: {
    alignItems: 'center',
    background: 'none',
    display: 'flex',
    padding: '0',
    justifyContent: 'space-between',
  },
  logo: {
    background: 'white',
    height: 'auto',
    padding: '1rem',
    width: '100%',
  },
  button: {
    width: '100%',
  },
  '@media (max-width: 900px)': {
    container: {
      minWidth: '100vw',
      padding: '0 1rem',
    },
  },
});

export default function Navigation({ children, logo, Right, userSignOut }) {
  const classes = useStyles();
  const [collapsed, setCollapsed] = useState(
    window && window.innerWidth <= 900
  );
  return (
    <Router>
      <Layout className={classes.root}>
        <Sider
          collapsed={collapsed}
          collapsedWidth="0"
          collapsible
          trigger={null}
        >
          <div>
            <img className={classes.logo} alt="logo" src={logo} />
          </div>

          <NavItems children={children} setCollapsed={setCollapsed} />
          <div className="ant-menu-item">
            <Button
              className={classes.button}
              ghost
              onClick={userSignOut}
              type="primary"
            >
              Cerrar Sesión
            </Button>
          </div>
        </Sider>
        <Layout className={classes.container}>
          <Header className={classes.header}>
            {collapsed ? (
              <MenuUnfoldOutlined onClick={() => setCollapsed(false)} />
            ) : (
              <MenuFoldOutlined onClick={() => setCollapsed(true)} />
            )}
            <Right />
          </Header>
          <Content className={classes.content}>
            <Switch>{children.map((item) => item)}</Switch>
          </Content>
          <Footer style={{ textAlign: 'center' }}>
            Runusa S.A. de C.V. ©2020
          </Footer>
        </Layout>
      </Layout>
    </Router>
  );
}
