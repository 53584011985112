import React, { useContext, useEffect } from 'react';
// Context
import AppContext from '../../context/app/AppContext';
// Material UI
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  button: {
    marginBottom: theme.spacing(2),
  },
  container: {
    maxWidth: '300px',
    width: '100%',
  },
}));

export default function Locations(params) {
  const classes = useStyles();
  const appContext = useContext(AppContext);
  const locations = appContext.user.locations;
  useEffect(() => {
    if (locations.length === 1) {
      appContext.locationSet(locations[0]);
    }
  });
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {locations.map((location, i) => (
          <Button
            key={i}
            className={classes.button}
            color="primary"
            fullWidth
            onClick={() => appContext.locationSet(location)}
            variant="outlined"
          >
            {location}
          </Button>
        ))}
      </div>
    </div>
  );
}
