import React from 'react';
import { Link } from 'react-router-dom';
import { format, parse } from 'date-fns';
// Ant Design
import { Card, Typography } from 'antd';
// Styles
import { createUseStyles } from 'react-jss';

const { Text } = Typography;

const useStyles = createUseStyles({
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
});

export default function Dryer({ startDate, uid, variety }) {
  const classes = useStyles();
  const t = parse(startDate.seconds, 't', new Date());
  return (
    <Link to={`/secadores/editar/${uid}`}>
      <Card>
        <div className={classes.head}>
          <Text strong>{variety}</Text>
          <Text>{format(t, 'dd/MM/yyyy')}</Text>
        </div>
      </Card>
    </Link>
  );
}
