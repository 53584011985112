import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
// Components
import Actions from '../Actions';
import Breadcrumbs from '../Breadcrumbs';
import { SelectBatch } from '../Batches';
import SelectVariety from '../SelectVariety';
import { SelectLocation } from '../Locations';
// Context
import AppContext from '../../context/app/AppContext';
// Material UI
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialogActions-root': {
      padding: theme.spacing(2),
    },
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(2),
    },
    '& .MuiButtonBase-root': {
      marginBottom: theme.spacing(2),
    },
  },
}));

export const AddTransfer = () => {
  const classes = useStyles();
  const appContext = useContext(AppContext);
  const history = useHistory();
  const [batchSource, setBatchSource] = useState('');
  const [driver, setDriver] = useState('');
  const [receiver, setReceiver] = useState('');
  const [sacksSent, setSacksSent] = useState('');
  const [variety, setVariety] = useState('');
  const [weightSent, setWeight] = useState('');
  const addTransfer = async () => {
    if ((batchSource, receiver && variety && weightSent)) {
      await appContext.transferAdd({
        batchSource,
        driver,
        receiver,
        sacksSent,
        variety,
        weightSent,
      });
      history.push('/transferencias');
    } else {
      alert('Llena todos los campos');
    }
  };
  return (
    <div className={classes.root}>
      <Breadcrumbs
        data={[
          { label: 'Transferencias', path: '/transferencias' },
          { label: 'Agregar' },
        ]}
        title="Agregar Transferencia"
      />
      <form noValidate autoComplete="off">
        <SelectLocation
          label="Destino"
          setValue={setReceiver}
          value={receiver}
        />

        <TextField
          fullWidth
          label="Conductor"
          onChange={(e) => setDriver(e.target.value)}
          value={driver}
          variant="outlined"
        />
        <SelectBatch
          label="Lote"
          setValue={setBatchSource}
          value={batchSource}
        />
        <SelectVariety setValue={setVariety} value={variety} />
        <TextField
          fullWidth
          label="Peso"
          onChange={(e) => setWeight(parseFloat(e.target.value))}
          value={weightSent}
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Bultos"
          onChange={(e) =>
            setSacksSent(e.target.value ? parseInt(e.target.value) : '')
          }
          value={sacksSent}
          variant="outlined"
        />
      </form>
      <Actions
        handleCancel={() => history.push('/transferencias')}
        handleSave={addTransfer}
        loading={appContext.loading}
      />
    </div>
  );
};
