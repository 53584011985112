import React, { useState } from 'react'
// Ant Design
import { Button, Input } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
// Styles
import { createUseStyles } from 'react-jss';
const useStyles = createUseStyles({
    root: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        minHeight: '100vh',
        overflowX: 'hidden',
        width: '100%',
    },
    button: {
        width: '100%'
    },
    container: {
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        height: '160px',
        maxWidth: '400px',
        padding: '0 1rem',
        width: '100%',
    }
});
export default function Login({ handleLogin, loading }) {
    const classes = useStyles();
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <Input
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Usuario"
                    prefix={<UserOutlined />}
                    size="large"
                    type="email"
                    value={email}
                />
                <Input
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Contraseña"
                    prefix={<LockOutlined />}
                    size="large"
                    type="password"
                    value={password}
                />
                <Button
                    className={classes.button}
                    loading={loading}
                    onClick={() => handleLogin(email, password)}
                    size="large"
                    type="primary"
                >
                    Iniciar Sesión
                </Button>
            </div>
        </div>
    )
}
