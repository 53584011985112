import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
// Create styles
const tableCol = {
  borderStyle: 'solid',
  borderWidth: 1,
  borderLeftWidth: 0,
  borderTopWidth: 0,
  padding: 5,
  width: '50%',
};
const styles = StyleSheet.create({
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableColNo: {
    ...tableCol,
    width: '5%',
  },
  tableColVariety: {
    ...tableCol,
    width: '15%',
  },
  tableColSacks: {
    ...tableCol,
    width: '10%',
  },
  tableColWeight: {
    ...tableCol,
    width: '15%',
  },
  tableColPerformance: {
    ...tableCol,
    width: '10%',
  },
  tableColPrice: {
    ...tableCol,
    width: '10%',
  },
  tableColTotal: {
    ...tableCol,
    width: '15%',
  },
  tableColObservations: {
    ...tableCol,
    width: '20%',
  },
  tableCell: {
    margin: 'auto',
    fontSize: 10,
  },
  tableHeader: {
    margin: 'auto',
    fontSize: 11,
    fontWeight: '800',
  },
});

// Create Document Component
export default function Table({ data }) {
  return (
    <View style={styles.table}>
      {/* TableHeader */}
      <View style={styles.tableRow}>
        <View style={styles.tableColNo}>
          <Text style={styles.tableHeader}>No</Text>
        </View>
        <View style={styles.tableColVariety}>
          <Text style={styles.tableHeader}>Variedad</Text>
        </View>
        <View style={styles.tableColSacks}>
          <Text style={styles.tableHeader}>Bultos</Text>
        </View>
        <View style={styles.tableColWeight}>
          <Text style={styles.tableHeader}>Peso</Text>
        </View>
        <View style={styles.tableColPerformance}>
          <Text style={styles.tableHeader}>Rend</Text>
        </View>
        <View style={styles.tableColPrice}>
          <Text style={styles.tableHeader}>Precio Unitario</Text>
        </View>
        <View style={styles.tableColTotal}>
          <Text style={styles.tableHeader}>Total</Text>
        </View>
        <View style={styles.tableColObservations}>
          <Text style={styles.tableHeader}>Observaciones</Text>
        </View>
      </View>
      {/* TableContent */}
      {data.map(
        ({ observations, performance, price, sacks, variety, weight }, i) => (
          <View key={i} style={styles.tableRow}>
            <View style={styles.tableColNo}>
              <Text style={styles.tableCell}>{i + 1}</Text>
            </View>
            <View style={styles.tableColVariety}>
              <Text style={styles.tableCell}>{variety}</Text>
            </View>
            <View style={styles.tableColSacks}>
              <Text style={styles.tableCell}>{sacks}</Text>
            </View>
            <View style={styles.tableColWeight}>
              <Text style={styles.tableCell}>{`${weight} Kg`}</Text>
            </View>
            <View style={styles.tableColPerformance}>
              <Text style={styles.tableCell}>{`${performance}%`}</Text>
            </View>
            <View style={styles.tableColPrice}>
              <Text style={styles.tableCell}>{`$${price}`}</Text>
            </View>
            <View style={styles.tableColTotal}>
              <Text style={styles.tableCell}>
                {`$${(weight * price).toFixed(2)}`}
              </Text>
            </View>
            <View style={styles.tableColObservations}>
              <Text style={styles.tableCell}>{observations}</Text>
            </View>
          </View>
        )
      )}
    </View>
  );
}
