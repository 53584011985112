import React, { useContext, useState } from 'react';
// Ant Design
import { Button } from 'antd';
// Context
import AppContext from '../../context/app/AppContext';

export default function TransferDelete({ transfer }) {
  const { loading, transferDelete } = useContext(AppContext);
  const [clicked, setClicked] = useState(false);
  function handleTransferRemove() {
    setClicked(true);
    transferDelete(transfer);
  }
  return (
    <Button
      type="link"
      loading={clicked && loading}
      onClick={handleTransferRemove}
    >
      Cancelar
    </Button>
  );
}
