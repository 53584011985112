import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
// Context
import AppContext from '../../context/app/AppContext';
// Ant Design
import { Select } from 'antd';
const { Option } = Select;

export const NavLocation = () => {
  const history = useHistory();
  const {
    location,
    locationSet,
    user: { locations },
  } = useContext(AppContext);
  const handleSelect = (value) => {
    history.push('/');
    locationSet(value);
  };
  return (
    <Select bordered={false} onChange={handleSelect} value={location}>
      {locations.map((label, i) => (
        <Option key={i} value={label}>
          {label}
        </Option>
      ))}
    </Select>
  );
};
