import React from 'react';
// Material UI
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/Remove';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    paddingRight: 0,
  },
  description: {
    flexBasis: '100%',
  },
}));

export default function ProcessBrief({
  handleRemove,
  process: { process, endTime, startTime },
}) {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <div className={classes.description} onClick={() => console.log(true)}>
        <Typography>{process}</Typography>
        <Typography>{`${startTime} - ${endTime}`}</Typography>
      </div>
      <IconButton
        aria-label="upload picture"
        color="inherit"
        component="span"
        onClick={handleRemove}
      >
        <RemoveIcon />
      </IconButton>
    </Paper>
  );
}
