import React, { useEffect, useState, Fragment } from 'react';
import { db } from '../../../firebase';
import { useParams } from 'react-router-dom';
import {
  Page,
  PDFViewer,
  Text,
  View,
  Document,
  StyleSheet,
} from '@react-pdf/renderer';
// Components
import Breadcrumbs from '../../Breadcrumbs';
import Header from './Header';
import Loader from '../../Loader';
import Signatures from './Signatures';
import Source from './Source';
import Summary from './Summary';
import Table from './Table';
// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  footer: {
    fontSize: 10,
    marginTop: 10,
  },
  viewer: {
    height: '100vh',
    width: '100%',
  },
});

export const EntryPdf = () => {
  const [data, setData] = useState(null);
  let { entry } = useParams();
  const entryId = entry.substring(0, entry.indexOf('-'));
  useEffect(() => {
    async function fetchData() {
      const doc = await db.collection('entries').doc(entry).get();
      setData(doc.data());
    }
    fetchData();
  }, [entry]);
  return (
    <Fragment>
      <Breadcrumbs
        data={[
          { label: 'Entradas', path: '/entradas' },
          { label: `Imprimir ${entryId}` },
        ]}
      />
      {data ? (
        <PDFViewer style={styles.viewer}>
          <Document>
            <Page size="A4" style={styles.page}>
              <Header date={data.date} id={data.id} />
              <Source
                buyer={data.buyer}
                phoneNumber={data.phoneNumber}
                producer={data.producer}
                source={data.source}
              />
              <Table data={data.loads} />
              <Summary data={data.loads} />
              <Signatures author={data.author} />
              <View>
                <Text style={styles.footer}>
                  Al firmar este documento declaro que el producto descrito en
                  la presente reciba es de origen legítimo y se vende a RUNUSA
                  SA DE CV con las facultades y derechos para hacerlo.
                </Text>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      ) : (
        <Loader />
      )}
    </Fragment>
  );
};
