import React, { useState } from 'react';
// Material UI
import AddCircle from '@material-ui/icons/AddCircle';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialogTitle: {
    padding: theme.spacing(0),
    paddingBottom: theme.spacing(2),
  },
  form: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginRight: theme.spacing(2),
  },
  total: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(1),
    textAlign: 'right',
  },
  totalData: {
    padding: `0 ${theme.spacing(1)}px`,
  },
}));

export default function Pallets({ handleClose, handlePallets, open }) {
  const classes = useStyles();
  const [pallets, setPallets] = useState([]);
  const [sacks, setSacks] = useState('');
  const [weight, setWeight] = useState('');

  const addPallet = () => {
    const palletsUpdate = [...pallets];
    if (weight) {
      palletsUpdate.push({ sacks, weight });
      setPallets(palletsUpdate);
      setSacks('');
      setWeight('');
    }
  };
  const removePallet = (i) => {
    const palletsUpdate = [...pallets];
    palletsUpdate.splice(i, 1);
    setPallets(palletsUpdate);
  };
  const handleSave = () => {
    handlePallets({
      sacks: pallets.reduce((t, p) => t + p.sacks, 0) || 0,
      weight: pallets.reduce((t, p) => t + p.weight, 0) || 0,
    });
    handleClose();
  };
  return (
    <Dialog onClose={handleClose} open={open}>
      <div className={classes.root}>
        <DialogTitle classes={{ root: classes.dialogTitle }}>
          Contar Tarimas
        </DialogTitle>
        <form noValidate autoComplete="off" className={classes.form}>
          <TextField
            className={classes.textField}
            fullWidth
            label="Peso"
            onChange={(e) => setWeight(parseFloat(e.target.value) || '')}
            value={weight}
            variant="outlined"
          />
          <TextField
            className={classes.textField}
            fullWidth
            label="Bultos"
            onChange={(e) => setSacks(parseInt(e.target.value) || '')}
            value={sacks}
            variant="outlined"
          />
          <IconButton
            color="primary"
            aria-label="Agregar Tarima"
            component="span"
            onClick={addPallet}
          >
            <AddCircle />
          </IconButton>
        </form>
        {pallets.length > 0 && (
          <div className={classes.container}>
            <Typography variant="button" component="span">
              Peso
            </Typography>
            <Typography variant="button" component="span">
              Bultos
            </Typography>
            <Typography variant="button" component="span" />
          </div>
        )}
        {pallets.map(({ sacks, weight }, i) => (
          <div key={i} className={classes.container}>
            <p>{`${weight} Kg`}</p>
            <p>{sacks}</p>
            <IconButton
              aria-label="Eliminar Tarima"
              component="span"
              onClick={(i) => removePallet(i)}
            >
              <RemoveCircle />
            </IconButton>
          </div>
        ))}
        <div className={classes.total}>
          <Typography display="block" component="span" variant="button">
            Total:
          </Typography>
          <div className={classes.totalData}>
            <Typography display="block" component="span" variant="button">
              {pallets.reduce((t, p) => t + p.weight, 0) || 0}
            </Typography>
            <Typography display="block" component="span" variant="button">
              {pallets.reduce((t, p) => t + p.sacks, 0) || 0}
            </Typography>
          </div>
          <div>
            <Typography display="block" component="span" variant="button">
              Kilos
            </Typography>
            <Typography display="block" component="span" variant="button">
              Bultos
            </Typography>
          </div>
        </div>
        <DialogActions>
          <Button color="primary" onClick={handleSave} variant="contained">
            GUARDAR
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}
