import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
// Ant Design
import { Input } from 'antd';
// Context
import AppContext from '../../context/app/AppContext';
// Components
import Actions from '../Actions';
import Breadcrumbs from '../Breadcrumbs';

export const WarehousesAdd = () => {
  const { loading, warehouseAdd } = useContext(AppContext);
  const history = useHistory();
  const [title, setTitle] = useState('');
  const handleAdd = () => {
    setTitle('');
    warehouseAdd(title);
    history.push('/bodegas');
  };
  return (
    <div>
      <Breadcrumbs
        data={[{ label: 'Bodegas', path: '/bodegas' }, { label: 'Agregar' }]}
        title="Agregar Bodega"
      />
      <Input
        autoFocus
        placeholder="Titulo"
        onChange={(e) => setTitle(e.target.value)}
        value={title}
      />
      <Actions
        handleCancel={() => history.push('/bodegas')}
        handleSave={handleAdd}
        loading={loading}
      />
    </div>
  );
};
