// Material UI
import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    primary: {
      dark: '#001c31',
      light: '#4b6d88',
      main: '#1d425b',
    },
    secondary: {
      dark: '#4d6847',
      light: '#a9c7a2',
      main: '#7a9673',
    },
  },
});
