import React, { Fragment, useContext, useEffect } from 'react';
import { firebase } from './firebase';
// Components
import Batches, { AddBatch, UpdateBatch } from './components/Batches';
import Dryers, { UpdateDryer, StartDryer } from './components/Dryers';
import Entries, { Entry, EntryPdf } from './components/Entries';
import Exits, { AddExit, ExitPdf, UpdateExit } from './components/Exits';
import Helmet from './components/Helmet';
import Home from './components/Home';
import Locations, { NavLocation } from './components/Locations';
import Login from './components/Login';
import Navigation, { NavElement } from './components/Navigation';
import Transfers, {
  AddTransfer,
  ReceiveTransfer,
} from './components/Transfers';
import Warehouses, { WarehousesAdd } from './components/Warehouses';
import Users, { UserAdd } from './components/Users';
// Context
import AppContext from './context/app/AppContext';
// Img
import logo from './img/logo.jpg';
// Ant Design
import {
  AppstoreOutlined,
  DatabaseOutlined,
  ExportOutlined,
  FireOutlined,
  ImportOutlined,
  SwapOutlined,
  TeamOutlined,
} from '@ant-design/icons';
// Styles
import 'antd/dist/antd.css';

const userGet = (userSet) => {
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      userSet(user);
    } else {
      userSet(false);
    }
  });
};

const Main = ({ location, userSignOut }) =>
  !location ? (
    <Locations />
  ) : (
    <Navigation logo={logo} Right={NavLocation} userSignOut={userSignOut}>
      <NavElement path="/entradas/agregar">
        <Entry />
      </NavElement>
      <NavElement path="/entradas/imprimir/:entry">
        <EntryPdf />
      </NavElement>
      <NavElement path="/entradas/:entry">
        <Entry />
      </NavElement>
      <NavElement Icon={ImportOutlined} label="Entradas" path="/entradas">
        <Entries />
      </NavElement>
      <NavElement path="/salidas/agregar">
        <AddExit />
      </NavElement>
      <NavElement path="/salidas/imprimir/:exit">
        <ExitPdf />
      </NavElement>
      <NavElement path="/salidas/:exit">
        <UpdateExit />
      </NavElement>
      <NavElement Icon={ExportOutlined} label="Salidas" path="/salidas">
        <Exits />
      </NavElement>
      <NavElement path="/transferencias/recibir/:transfer/:variety/:weightSent">
        <ReceiveTransfer />
      </NavElement>
      <NavElement path="/transferencias/agregar">
        <AddTransfer />
      </NavElement>
      <NavElement
        Icon={SwapOutlined}
        label="Transferencias"
        path="/transferencias"
      >
        <Transfers />
      </NavElement>
      <NavElement path="/bodegas/agregar">
        <WarehousesAdd />
      </NavElement>
      <NavElement Icon={DatabaseOutlined} label="Bodegas" path="/bodegas">
        <Warehouses />
      </NavElement>
      <NavElement path="/lotes/agregar">
        <AddBatch />
      </NavElement>
      <NavElement path="/lotes/editar/:batch">
        <UpdateBatch />
      </NavElement>
      <NavElement Icon={AppstoreOutlined} label="Lotes" path="/lotes">
        <Batches />
      </NavElement>
      <NavElement path="/secadores/editar/:dryer">
        <UpdateDryer />
      </NavElement>
      <NavElement path="/secadores/iniciar">
        <StartDryer />
      </NavElement>
      <NavElement Icon={FireOutlined} label="Secadores" path="/secadores">
        <Dryers />
      </NavElement>
      <NavElement path="/usuarios/agregar">
        <UserAdd />
      </NavElement>
      <NavElement Icon={TeamOutlined} label="Usuarios" path="/usuarios">
        <Users />
      </NavElement>
      <NavElement path="/">
        <Home />
      </NavElement>
    </Navigation>
  );

export default function App() {
  const {
    location,
    loading,
    user,
    userSet,
    userSignIn,
    userSignOut,
  } = useContext(AppContext);
  useEffect(() => {
    userGet(userSet);
    // eslint-disable-next-line
  }, []);
  return (
    <Fragment>
      <Helmet />
      {!user ? (
        <Login handleLogin={userSignIn} loading={loading} logo={logo} />
      ) : (
        <Main location={location} userSignOut={userSignOut} />
      )}
    </Fragment>
  );
}
