import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
// Ant Design
import { DatePicker, Space } from 'antd';
// Components
import AddButton from '../AddButton';
import Breadcrumbs from '../Breadcrumbs';
import Loader from '../Loader';
import EntryBrief from './EntryBrief';
import Search from '../Search';
// Context
import AppContext from '../../context/app/AppContext';

const { RangePicker } = DatePicker;

export default function Entries() {
  const { entries, entriesGet } = useContext(AppContext);
  const [keywords, setKeywords] = useState([]);
  const [range, setRange] = useState(null);
  const history = useHistory();
  useEffect(() => {
    entriesGet({});
    // eslint-disable-next-line
  }, []);
  function getDates(dates) {
    if (dates) {
      return [
        moment(dates[0]).format('YYYY-MM-DD'),
        moment(dates[1]).format('YYYY-MM-DD'),
      ];
    } else {
      return null;
    }
  }
  function onRangePick(dates) {
    setRange(dates);
    entriesGet({ keywords, range: getDates(dates) });
  }
  function onSearch(search) {
    entriesGet({ keywords: search, range: getDates(range) });
  }
  return (
    <Fragment>
      <Breadcrumbs
        data={[{ label: 'Entradas', path: '/entradas' }]}
        title="Entradas"
      />
      <Space direction="vertical">
        <Search
          keywords={keywords}
          setKeywords={setKeywords}
          onSearch={onSearch}
        />
        <RangePicker onChange={onRangePick} value={range} />
        {entries ? (
          entries.map((entry, i) => <EntryBrief key={i} entry={entry} />)
        ) : (
          <Loader />
        )}
      </Space>
      <AddButton handleAdd={() => history.push('/entradas/agregar')} />
    </Fragment>
  );
}
