import React, { useContext, useEffect } from 'react';
// Context
import AppContext from '../../context/app/AppContext';
// Material UI
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

export const SelectWarehouse = ({ disabled, value, setValue }) => {
  const { warehouses, warehousesGet } = useContext(AppContext);
  useEffect(() => {
    warehousesGet();
    // eslint-disable-next-line
  }, []);
  const options = [];
  warehouses.forEach(({ title, uid }) => {
    options.push({ label: title, value: uid });
  });
  if (options && options.length > 1 && !value) {
    setValue(options[1].uid);
  }
  return (
    <Autocomplete
      disabled={disabled}
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      getOptionLabel={(option) => option.label || ''}
      options={options || []}
      style={{ width: '100%' }}
      renderInput={(params) => (
        <TextField {...params} label="Bodega" variant="outlined" />
      )}
    />
  );
};
