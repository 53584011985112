import React, { useContext } from 'react';
// Context
import AppContext from '../../context/app/AppContext';
// Material UI
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

export const SelectLocation = ({ label, setValue, value }) => {
  const appContext = useContext(AppContext);
  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      options={appContext.locations || []}
      getOptionLabel={(option) => option || ''}
      style={{ width: '100%' }}
      renderInput={(params) => (
        <TextField {...params} label={label || 'Sucursal'} variant="outlined" />
      )}
    />
  );
};
