import React from 'react';
import { useHistory } from 'react-router-dom';
// Ant Design
import { Button } from 'antd';

export default function TransferReceive({ transfer }) {
  const { variety, weightSent, uid } = transfer;
  const history = useHistory();
  function handleTransferReceive() {
    history.push(`/transferencias/recibir/${uid}/${variety}/${weightSent}`);
  }
  return (
    <Button type="link" onClick={handleTransferReceive}>
      Aceptar
    </Button>
  );
}
