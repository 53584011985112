import React from 'react';
import { Link } from 'react-router-dom';
// Ant Design
import {
  AppstoreOutlined,
  DatabaseOutlined,
  ExportOutlined,
  FireOutlined,
  ImportOutlined,
  SwapOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { Card, Typography } from 'antd';

const { Meta } = Card;
const { Title } = Typography;

export default function Home() {
  return (
    <div>
      <Title level={2}>Inicio</Title>
      <Link to="/entradas">
        <Card>
          <Meta avatar={<ImportOutlined />} title="Entradas" />
        </Card>
      </Link>
      <Link to="/salidas">
        <Card>
          <Meta avatar={<ExportOutlined />} title="Salidas" />
        </Card>
      </Link>
      <Link to="/transferencias">
        <Card>
          <Meta avatar={<SwapOutlined />} title="Transferencias" />
        </Card>
      </Link>
      <Link to="/bodegas">
        <Card>
          <Meta avatar={<DatabaseOutlined />} title="Bodegas" />
        </Card>
      </Link>
      <Link to="/lotes">
        <Card>
          <Meta avatar={<AppstoreOutlined />} title="Lotes" />
        </Card>
      </Link>
      <Link to="/secadores">
        <Card>
          <Meta avatar={<FireOutlined />} title="Secadores" />
        </Card>
      </Link>
      <Link to="/usuarios">
        <Card>
          <Meta avatar={<TeamOutlined />} title="Usuarios" />
        </Card>
      </Link>
    </div>
  );
}
