import React from 'react';
import { useHistory } from 'react-router-dom';
// Ant Design
import { Menu } from 'antd';

export default function NavItems({ children, setCollapsed }) {
  const history = useHistory();
  const handleClick = (path) => {
    history.push(path);
    if (window && window.innerWidth <= 900) {
      setCollapsed(true);
    }
  };
  return (
    <Menu theme="dark" mode="inline" defaultSelectedKeys={['4']}>
      {children.map(
        ({ props: { Icon, label, path } }, i) =>
          label && (
            <Menu.Item
              key={i}
              icon={<Icon />}
              onClick={() => handleClick(path)}
            >
              {label}
            </Menu.Item>
          )
      )}
    </Menu>
  );
}
