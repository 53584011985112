import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { db } from '../../firebase';
// Components
import Actions from '../Actions';
import Breadcrumbs from '../Breadcrumbs';
import { SelectWarehouse } from '../Warehouses';
// Context
import AppContext from '../../context/app/AppContext';
// Material UI
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  form: {
    '& .MuiDialogActions-root': {
      padding: theme.spacing(2),
    },
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(2),
    },
    '& .MuiButtonBase-root': {
      marginBottom: theme.spacing(2),
    },
  },
}));

export function UpdateBatch() {
  const history = useHistory();
  const classes = useStyles();
  const [description, setDescription] = useState('');
  const [nuts, setNuts] = useState({});
  const [title, setTitle] = useState('');
  const [warehouse, setWarehouse] = useState({ label: '', value: '' });
  const appContext = useContext(AppContext);
  let { batch } = useParams();
  useEffect(() => {
    async function fetchData() {
      const doc = await db.doc(`batches/${batch}`).get();
      if (doc.data()) {
        setDescription(doc.data().description);
        setNuts(doc.data().nuts);
        setTitle(doc.data().title);
        setWarehouse({
          label: doc.data().warehouseTitle,
          value: doc.data().warehouse,
        });
      }
    }
    if (batch) {
      fetchData();
    }
  }, [batch]);

  const setSacks = (variety, sacks) => {
    const nutsUpdate = Object.assign({}, nuts);
    nutsUpdate[variety]['sacks'] = sacks;
    setNuts(nutsUpdate);
  };
  const setWeight = (variety, weight) => {
    const nutsUpdate = Object.assign({}, nuts);
    nutsUpdate[variety]['weight'] = weight;
    setNuts(nutsUpdate);
  };
  const handleSave = async () => {
    const update = {
      description,
      title,
    };
    if (nuts) {
      update['nuts'] = nuts;
    }
    if (warehouse) {
      update['warehouse'] = warehouse.value;
    }
    await appContext.batchUpdate(update, batch);
    alert('Lote actualizado');
    setDescription('');
    setNuts({});
    setTitle('');
    history.push('/lotes');
  };
  return (
    <Fragment>
      <Breadcrumbs
        data={[
          { label: 'Lotes', path: '/lotes' },
          { label: 'Editar' },
          { label: title },
        ]}
        title="Editar Lote"
      />
      <form noValidate autoComplete="off" className={classes.form}>
        <TextField
          fullWidth
          label="Titulo"
          onChange={(e) => setTitle(e.target.value)}
          value={title}
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Descripción"
          onChange={(e) => setDescription(e.target.value)}
          value={description}
          variant="outlined"
        />
        <SelectWarehouse disabled setValue={setWarehouse} value={warehouse} />
        {nuts &&
          Object.values(nuts).map(({ label, sacks, weight }, i) => (
            <Fragment key={i}>
              <p>{label}</p>
              <TextField
                fullWidth
                label="Bultos"
                onChange={(e) =>
                  setSacks(label, parseInt(e.target.value) || '')
                }
                type="number"
                value={sacks}
                variant="outlined"
              />
              <TextField
                fullWidth
                label="Peso"
                onChange={(e) =>
                  setWeight(label, parseFloat(e.target.value) || '')
                }
                type="number"
                value={weight}
                variant="outlined"
              />
            </Fragment>
          ))}
        <Actions
          handleCancel={() => history.push('/lotes')}
          handleSave={handleSave}
          loading={appContext.loading}
        />
      </form>
    </Fragment>
  );
}
