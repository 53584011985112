import React from 'react';
import { createUseStyles } from 'react-jss';
// Ant Design
import { Affix, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const useStyles = createUseStyles({
  root: {
    bottom: 68,
    position: 'fixed',
    right:
      window && window.innerWidth >= 1160
        ? (window.innerWidth - 200 - 960) / 2 - 32
        : '2rem',
  },
  '@media (max-width: 900px)': {
    root: {
      bottom: 68,
      right: '1rem',
    },
  },
});

export default function AddButton({ handleAdd }) {
  const classes = useStyles();
  return (
    <Affix className={classes.root} offsetTop={10}>
      <Button
        type="primary"
        shape="circle"
        icon={<PlusOutlined />}
        onClick={handleAdd}
        size="large"
      />
    </Affix>
  );
}
