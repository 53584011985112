import React, { useEffect, useState, Fragment } from 'react';
import { db } from '../../../firebase';
import { useParams } from 'react-router-dom';
import { Page, PDFViewer, Document, StyleSheet } from '@react-pdf/renderer';
// Components
import Breadcrumbs from '../../Breadcrumbs';
import Destination from './Destination';
import Header from './Header';
import Loader from '../../Loader';
import Signatures from './Signatures';
import Summary from './Summary';
import Table from './Table';
// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  footer: {
    fontSize: 10,
    marginTop: 10,
  },
  viewer: {
    height: '100vh',
    width: '100%',
  },
});

export const ExitPdf = () => {
  const [data, setData] = useState(null);
  let { exit } = useParams();
  useEffect(() => {
    async function fetchData() {
      const doc = await db.doc(`exits/${exit}`).get();
      setData(doc.data());
    }
    fetchData();
  }, [exit]);
  return (
    <Fragment>
      <Breadcrumbs
        data={[
          { label: 'Salidas', path: '/salidas' },
          { label: `Imprimir ${data ? data.id : ''}` },
        ]}
      />
      {data ? (
        <PDFViewer style={styles.viewer}>
          <Document>
            <Page size="A4" style={styles.page}>
              <Header date={data.date} id={data.id} />
              <Destination
                destination={data.destination}
                driver={data.driver}
              />
              <Table data={data.loads} />
              <Summary data={data.loads} />
              <Signatures author={data.author} />
            </Page>
          </Document>
        </PDFViewer>
      ) : (
        <Loader />
      )}
    </Fragment>
  );
};
