import React from 'react';
import { format, parse } from 'date-fns';
import { Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import logo from '../../../img/logo.jpg';
// Create styles
const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 10,
  },
  data: {
    fontSize: 8,
    textAlign: 'center',
    width: '60%',
  },
  dataSubheader: {
    fontSize: 8,
  },
  dataTitle: {
    fontSize: 10,
  },
  date: {
    fontSize: 15,
    paddingTop: 5,
  },
  img: {
    height: 'auto',
    width: '20%',
  },
  series: {
    textAlign: 'right',
    width: '20%',
  },
});

// Create Document Component
export default function Header({ date, id }) {
  const t = parse(date.seconds, 't', new Date());
  return (
    <View style={styles.root}>
      <View style={styles.img}>
        <Image src={logo} />
      </View>
      <View style={styles.data}>
        <Text style={styles.dataTitle}>COMPRA-VENTA DE NUEZ</Text>
        <Text style={styles.dataSubheader}>MATRIZ</Text>
        <Text>Ave. Plutarco Elías Calles #2002 Amp. Benito Juárez</Text>
        <Text>Tel. (639) 474-10-96, 472-66-26, 474-63-45</Text>
        <Text>Cd. Delicias, Chih.</Text>
        <Text style={styles.dataSubheader}>SUCURSAL</Text>
        <Text>Ave. 6a Nte. #623 Tel. (639) 474-65-73</Text>
        <Text>Cd. Delicias, Chih.</Text>
      </View>
      <View style={styles.series}>
        <Text>{id}</Text>
        <Text style={styles.date}>{format(t, 'dd/MM/yyyy')}</Text>
        <Text style={styles.date}>{format(t, 'hh:mm aa')}</Text>
      </View>
    </View>
  );
}
