import React, { useState } from 'react';
// Ant Design
import { Input, Tag } from 'antd';
const { Search } = Input;

export default function SearchComponent({ keywords, setKeywords, onSearch }) {
  const [input, setInput] = useState([]);
  function addKeyword(keyword) {
    const update = [...keywords];
    update.push(keyword);
    setKeywords(update);
    setInput('');
    onSearch(update);
  }
  function removeKeyword(index) {
    const update = [...keywords];
    update.splice(index, 1);
    setKeywords(update);
    onSearch(update);
  }
  return (
    <div>
      <Search
        disabled={keywords.length >= 10}
        placeholder="Buscar"
        onSearch={addKeyword}
        onChange={(e) => setInput(e.target.value)}
        value={input}
      />
      {keywords.map((keyword, i) => (
        <Tag
          closable
          key={i}
          onClose={(e) => {
            e.preventDefault();
            removeKeyword(i);
          }}
        >
          {keyword}
        </Tag>
      ))}
    </div>
  );
}
