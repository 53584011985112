import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
// Components
import AddLoad from './Loads/AddLoad';
import Breadcrumbs from '../Breadcrumbs';
import LoadBrief from './Loads/LoadBrief';
import Actions from '../Actions';
// Context
import AppContext from '../../context/app/AppContext';
// Material UI
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialogActions-root': {
      padding: theme.spacing(2),
    },
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(2),
    },
    '& .MuiButtonBase-root': {
      marginBottom: theme.spacing(2),
    },
  },
}));

export const AddExit = () => {
  const history = useHistory();
  const classes = useStyles();
  const appContext = useContext(AppContext);
  const [destination, setDestination] = useState('');
  const [driver, setDriver] = useState('');
  const [loads, setLoads] = useState([]);
  const [open, setOpen] = useState(false);
  const handleLoadAdd = (load) => {
    const loadsUpdate = loads.slice();
    loadsUpdate.push(load);
    setLoads(loadsUpdate);
  };
  const handleLoadRemove = (i) => {
    const loadsUpdate = loads.slice();
    loadsUpdate.splice(i, 1);
    setLoads(loadsUpdate);
  };
  const saveExit = async () => {
    await appContext.exitAdd({ destination, driver, loads });
    setDestination('');
    setDriver('');
    setLoads([]);
    history.push('/salidas');
  };
  return (
    <div className={classes.root}>
      <AddLoad
        open={open}
        handleClose={() => setOpen(false)}
        addLoad={handleLoadAdd}
      />
      <Breadcrumbs
        data={[{ label: 'Salidas', path: '/salidas' }, { label: 'Agregar' }]}
        title="Agregar Salida"
      />
      <form noValidate autoComplete="off">
        <TextField
          fullWidth
          label="Destino"
          onChange={(e) => setDestination(e.target.value)}
          value={destination}
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Conductor"
          onChange={(e) => setDriver(e.target.value)}
          value={driver}
          variant="outlined"
        />
        {loads &&
          loads.map((load, i) => (
            <LoadBrief
              handleRemove={() => handleLoadRemove(i)}
              key={i}
              load={load}
            />
          ))}
        <Button
          color="primary"
          fullWidth
          onClick={() => setOpen(true)}
          variant="outlined"
        >
          Agregar Carga
        </Button>
      </form>
      <Actions
        handleCancel={() => history.push('/salidas')}
        handleSave={saveExit}
        loading={appContext.loading}
      />
    </div>
  );
};
