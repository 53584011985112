import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { db } from '../../firebase';
// Components
import AddButton from '../AddButton';
import Breadcrumbs from '../Breadcrumbs';
import Dryer from './Dryer';
// Context
import AppContext from '../../context/app/AppContext';

export default function Dryers() {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const [dryers, setDryers] = useState([]);
  useEffect(() => {
    db.collection('dryers')
      .orderBy('startDate', 'desc')
      .where('location', '==', appContext.location)
      .onSnapshot((querySnapshot) => {
        const data = [];
        querySnapshot.forEach((doc) => {
          data.push({ ...doc.data(), uid: doc.id });
        });
        setDryers(data);
      });
    // eslint-disable-next-line
  }, []);
  return (
    <Fragment>
      <Breadcrumbs
        data={[{ label: 'Secadores', path: '/secadores' }]}
        title="Secadores"
      />
      {dryers.map(({ startDate, uid, variety }, i) => (
        <Dryer key={i} startDate={startDate} uid={uid} variety={variety} />
      ))}
      <AddButton handleAdd={() => history.push('/secadores/iniciar')} />
    </Fragment>
  );
}
