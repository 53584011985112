import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
// Create styles
const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 50,
  },
  signature: {
    borderStyle: 'solid',
    borderTopWidth: 1,
    fontSize: 12,
    textAlign: 'center',
    width: '25%',
  },
});

// Create Document Component
export default function Signatures({ author }) {
  return (
    <View style={styles.root}>
      <View style={styles.signature}>
        <Text>{`Realizó: ${author || ''}`}</Text>
      </View>
      <View style={styles.signature}>
        <Text>Revisó</Text>
      </View>
      <View style={styles.signature}>
        <Text>Firma de conductor</Text>
      </View>
    </View>
  );
}
