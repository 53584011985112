import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
// Components
import Actions from '../Actions';
import { SelectBatch } from '../Batches';
// Context
import AppContext from '../../context/app/AppContext';
// Material UI
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialogActions-root': {
      padding: theme.spacing(2),
    },
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(2),
    },
    '& .MuiButtonBase-root': {
      marginBottom: theme.spacing(2),
    },
  },
}));

export const ReceiveTransfer = () => {
  const classes = useStyles();
  const { transfer, variety, weightSent } = useParams();
  const appContext = useContext(AppContext);
  const history = useHistory();
  const [batch, setBatch] = useState('');
  const [sacksReceived, setSacksReceived] = useState('');
  const [weightReceived, setWeightReceived] = useState('');

  const receiveTransfer = async () => {
    if ((batch, weightReceived)) {
      await appContext.transferReceive({
        destination: { batch, variety, sacksReceived, weightReceived },
        transfer,
      });
      history.push('/transferencias');
    } else {
      alert('Llena todos los campos');
    }
  };
  return (
    <div className={classes.root}>
      <form noValidate autoComplete="off">
        <TextField
          disabled
          fullWidth
          label="Variedad"
          value={variety}
          variant="outlined"
        />
        <TextField
          disabled
          fullWidth
          label="Peso segun el emisor"
          value={weightSent}
          variant="outlined"
        />
        <SelectBatch label="Destino" setValue={setBatch} value={batch} />
        <TextField
          fullWidth
          label="Peso recibido en Kg"
          onChange={(e) => setWeightReceived(parseFloat(e.target.value))}
          value={weightReceived}
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Bultos recibidos"
          onChange={(e) =>
            setSacksReceived(e.target.value ? parseInt(e.target.value) : '')
          }
          value={sacksReceived}
          variant="outlined"
        />
      </form>
      <Actions
        handleCancel={() => history.push('/transferencias')}
        handleSave={receiveTransfer}
        loading={appContext.loading}
      />
    </div>
  );
};
