import React, { useState } from 'react';
// Components
import Pallets from '../../Pallets';
import { SelectBatch } from '../../Batches';
import SelectVariety from '../../SelectVariety';
// Material UI
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiButtonBase-root': {
      marginBottom: theme.spacing(2),
    },
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(2),
      width: '100%',
    },
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(2),
    },
  },
}));

export default function FullScreenDialog({ handleClose, handleSave }) {
  const classes = useStyles();
  const [batch, setBatch] = useState('');
  const [open, setOpen] = useState(false);
  const [performance, setPerformance] = useState('');
  const [price, setPrice] = useState('');
  const [sacks, setSacks] = useState('');
  const [weight, setWeight] = useState('');
  const [variety, setVariety] = useState('');
  const handleClick = () => {
    if (variety && weight) {
      handleSave({
        batch,
        sacks,
        variety,
        performance,
        price,
        weight,
      });
      setBatch('');
      setSacks('');
      setVariety('');
      setPerformance('');
      setPrice('');
      setWeight('');
      handleClose();
    } else {
      alert('Debes incluir la variedad y el peso');
    }
  };
  const handlePallets = (pallets) => {
    setSacks(pallets.sacks);
    setWeight(pallets.weight);
  };
  return (
    <div className={classes.root}>
      <Pallets
        open={open}
        handleClose={() => setOpen(false)}
        handlePallets={handlePallets}
      />
      <form noValidate autoComplete="off">
        <SelectBatch value={batch} setValue={setBatch} />
        <SelectVariety value={variety} setValue={setVariety} />
        <TextField
          fullWidth
          label="Rendimiento en Porcentaje"
          onChange={(e) => setPerformance(parseFloat(e.target.value))}
          type="number"
          value={performance}
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Bultos"
          onChange={(e) =>
            setSacks(e.target.value ? parseInt(e.target.value) : '')
          }
          type="number"
          value={sacks}
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Peso en Kg"
          onChange={(e) => setWeight(parseFloat(e.target.value))}
          type="number"
          value={weight}
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Precio Unitario"
          onChange={(e) => setPrice(parseFloat(e.target.value))}
          type="number"
          value={price}
          variant="outlined"
        />
      </form>
      <Button
        color="primary"
        fullWidth
        onClick={() => handleClick()}
        variant="outlined"
      >
        Guardar
      </Button>
      <Button color="primary" fullWidth onClick={() => setOpen(true)}>
        CALCULADORA
      </Button>
    </div>
  );
}
