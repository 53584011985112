import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { db } from '../../firebase';
// Components
import Actions from '../Actions';
import AddProcess from './Process/AddProcess';
import ProcessBrief from './Process/ProcessBrief';
import { SelectBatch } from '../Batches';
import Breadcrumbs from '../Breadcrumbs';
import SelectVariety from '../SelectVariety';
// Context
import AppContext from '../../context/app/AppContext';
// Material UI
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  form: {
    '& .MuiDialogActions-root': {
      padding: theme.spacing(2),
    },
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(2),
    },
    '& .MuiButtonBase-root': {
      marginBottom: theme.spacing(2),
    },
  },
}));

const getDate = (d) => {
  const date = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
  const hours = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours();
  const minutes = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes();
  const month =
    d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;

  return `${d.getFullYear()}-${month}-${date}T${hours}:${minutes}`;
};

export function UpdateDryer() {
  const history = useHistory();
  const { dryer } = useParams();
  const classes = useStyles();
  const [batch, setBatch] = useState('');
  const [cylinder, setCylinder] = useState('');
  const [endDate, setED] = useState(getDate(new Date()));
  const [endHumidity, setEH] = useState('');
  const [endWeight, setEW] = useState('');
  const [open, setOpen] = useState(false);
  const [processes, setProcesses] = useState([]);
  const [startDate, setSD] = useState(getDate(new Date()));
  const [startHumidity, setSH] = useState('');
  const [startWeight, setSW] = useState('');
  const [variety, setVariety] = useState('');
  const appContext = useContext(AppContext);
  const addProcess = (process) => {
    const processesUpdate = processes.slice();
    processesUpdate.push(process);
    setProcesses(processesUpdate);
  };
  const processRemove = (i) => {
    const processesUpdate = processes.slice();
    processesUpdate.splice(i, 1);
    setProcesses(processesUpdate);
  };
  useEffect(() => {
    async function fetchData() {
      const doc = await db.doc(`dryers/${dryer}`).get();
      setBatch(doc.data().batch);
      setCylinder(doc.data().cylinder);
      setED(getDate(doc.data().startDate.toDate()));
      setEH(doc.data().endHumidity);
      setEW(doc.data().endWeight);
      setProcesses(doc.data().processes);
      setSD(getDate(doc.data().endDate.toDate()));
      setSH(doc.data().startHumidity);
      setSW(doc.data().startWeight);
      setVariety(doc.data().variety);
    }
    fetchData();
    // eslint-disable-next-line
  }, []);
  const updateDryer = async () => {
    appContext.dryerEnd({
      batch,
      cylinder,
      endDate: new Date(endDate),
      endHumidity,
      endWeight,
      id: dryer,
      processes,
      startDate: new Date(startDate),
      startHumidity,
      startWeight,
      variety,
    });
    history.push('/secadores');
  };
  return (
    <Fragment>
      <Breadcrumbs
        data={[
          { label: 'Secadores', path: '/secadores' },
          { label: 'Actualizar' },
        ]}
        title="Actualizar Secador"
      />
      <AddProcess
        open={open}
        handleClose={() => setOpen(false)}
        addProcess={addProcess}
      />
      <form noValidate autoComplete="off" className={classes.form}>
        <TextField
          fullWidth
          label="Fecha de Inicio"
          onChange={(e) => setSD(e.target.value)}
          type="datetime-local"
          value={startDate}
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Fecha de termino"
          onChange={(e) => setED(e.target.value)}
          type="datetime-local"
          value={endDate}
          variant="outlined"
        />
        <SelectBatch setValue={setBatch} value={batch} />
        <SelectVariety setValue={setVariety} value={variety} />
        <TextField
          fullWidth
          label="Cilindro"
          onChange={(e) => setCylinder(e.target.value)}
          value={cylinder}
          variant="outlined"
          type="number"
        />
        <TextField
          fullWidth
          label="Humedad inicial"
          onChange={(e) => setSH(e.target.value)}
          value={startHumidity}
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Peso inicial"
          onChange={(e) => setSW(e.target.value)}
          value={startWeight}
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Humedad final"
          onChange={(e) => setEH(e.target.value)}
          value={endHumidity}
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Peso final"
          onChange={(e) => setEW(e.target.value)}
          value={endWeight}
          variant="outlined"
        />
        {processes.map((process, i) => (
          <ProcessBrief
            handleRemove={() => processRemove(i)}
            key={i}
            process={process}
          >
            {i}
          </ProcessBrief>
        ))}
        <Button
          color="primary"
          fullWidth
          onClick={() => setOpen(true)}
          variant="outlined"
        >
          Agregar Carga
        </Button>
        <Actions
          handleCancel={() => history.push('/secadores')}
          handleSave={updateDryer}
          loading={appContext.loading}
        />
      </form>
    </Fragment>
  );
}

export default UpdateDryer;
