import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
// Create styles
const styles = StyleSheet.create({
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableCell: {
    fontSize: 15,
  },
  tableCol: {
    borderStyle: 'solid',
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: '50%',
  },
  tableRow: {
    flexDirection: 'row',
    margin: 'auto',
    paddingBottom: 10,
  },
});

// Create Document Component
export default function Destinations({ destination, driver }) {
  return (
    <View style={styles.table}>
      {/* Row */}
      <View style={styles.tableRow}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{`Destino: ${
            destination || ''
          }`}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{`Conductor: ${driver || ''}`}</Text>
        </View>
      </View>
    </View>
  );
}
