import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { db } from '../../firebase';
// Components
import AddLoad from './Loads/AddLoad';
import Breadcrumbs from '../Breadcrumbs';
import LoadBrief from './Loads/LoadBrief';
import Actions from '../Actions';
// Context
import AppContext from '../../context/app/AppContext';
// Material UI
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  form: {
    '& .MuiDialogActions-root': {
      padding: theme.spacing(2),
    },
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(2),
    },
    '& .MuiButtonBase-root': {
      marginBottom: theme.spacing(2),
    },
  },
}));

export function Entry() {
  const history = useHistory();
  const classes = useStyles();
  const [buyer, setBuyer] = useState('');
  const [id, setId] = useState('');
  const [loads, setLoads] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [producer, setProducer] = useState('');
  const [source, setSource] = useState('');
  const appContext = useContext(AppContext);
  const [open, setOpen] = useState(false);
  let { entry } = useParams();
  useEffect(() => {
    async function fetchData() {
      const doc = await db.collection('entries').doc(entry).get();
      setBuyer(doc.data().buyer);
      setId(doc.data().id);
      setLoads(doc.data().loads);
      setPhoneNumber(doc.data().phoneNumber);
      setProducer(doc.data().producer);
      setSource(doc.data().source);
    }
    if (entry) {
      fetchData();
    }
  }, [entry]);
  const saveEntry = async () => {
    await appContext.entrySave(
      {
        buyer,
        id,
        loads,
        phoneNumber,
        producer,
        source,
      },
      entry
    );
    setBuyer('');
    setId('');
    setLoads([]);
    setPhoneNumber('');
    setProducer('');
    setSource('');
    history.push('/entradas');
  };
  const handleLoadAdd = (load) => {
    const loadsUpdate = loads.slice();
    loadsUpdate.push(load);
    setLoads(loadsUpdate);
  };
  const handleLoadRemove = (i) => {
    const loadsUpdate = loads.slice();
    loadsUpdate.splice(i, 1);
    setLoads(loadsUpdate);
  };
  return (
    <Fragment>
      <AddLoad
        open={open}
        handleClose={() => setOpen(false)}
        addLoad={handleLoadAdd}
      />
      <Breadcrumbs
        data={[
          { label: 'Entradas', path: '/entradas' },
          { label: id ? id : 'Agregar' },
        ]}
        title={`${id ? 'Editar' : 'Agregar'} Entrada`}
      />
      <form noValidate autoComplete="off" className={classes.form}>
        <TextField
          fullWidth
          label="Origen"
          onChange={(e) => setSource(e.target.value)}
          value={source}
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Productor"
          onChange={(e) => setProducer(e.target.value)}
          value={producer}
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Teléfono"
          onChange={(e) => setPhoneNumber(e.target.value)}
          value={phoneNumber}
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Comprador"
          onChange={(e) => setBuyer(e.target.value)}
          value={buyer}
          variant="outlined"
        />
        {loads &&
          loads.map((load, i) => (
            <LoadBrief
              handleRemove={() => handleLoadRemove(i)}
              key={i}
              load={load}
            />
          ))}
        <Button
          color="primary"
          fullWidth
          onClick={() => setOpen(true)}
          variant="outlined"
        >
          Agregar Carga
        </Button>
        <Actions
          handleCancel={() => history.push('/entradas')}
          handleSave={saveEntry}
          loading={appContext.loading}
        />
      </form>
    </Fragment>
  );
}
