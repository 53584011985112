import React, { Fragment, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// Ant Design
import { Typography } from 'antd';
// Context
import AppContext from '../../context/app/AppContext';
// Components
import AddButton from '../AddButton';
import Breadcrumbs from '../Breadcrumbs';
import Warehouse from './Warehouse';

const { Paragraph } = Typography;

export default function Warehouses() {
  const { warehouses, warehousesGet } = useContext(AppContext);
  const history = useHistory();
  useEffect(() => {
    warehousesGet();
    // eslint-disable-next-line
  }, []);
  return (
    <Fragment>
      <Breadcrumbs
        data={[{ label: 'Bodegas', path: '/bodegas' }]}
        title="Bodegas"
      />
      {warehouses.length > 0 &&
        warehouses.map((data, i) => <Warehouse key={i} data={data} />)}
      {!warehouses.length > 0 && (
        <Paragraph>No has agregado ninguna bodega</Paragraph>
      )}
      <AddButton handleAdd={() => history.push('/bodegas/agregar')} />
    </Fragment>
  );
}
