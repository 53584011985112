import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
// Create styles
const styles = StyleSheet.create({
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 20,
  },
  tableTitle: {
    fontSize: 15,
    textAlign: 'right',
  },
  tableCell: {
    fontSize: 12,
    textAlign: 'right',
  },
  tableCol: {
    borderStyle: 'solid',
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: '25%',
  },
  tableRow: {
    flexDirection: 'row',
    margin: 'auto',
  },
});

// Create Document Component
export default function Summary({ data }) {
  let sacks = 0;
  let total = 0;
  let weight = 0;
  data.forEach((element) => {
    sacks = sacks + element.sacks;
    total = total + element.price * element.weight;
    weight = weight + element.weight;
  });
  return (
    <View style={styles.table}>
      {/* Row */}
      <View style={styles.tableRow}>
        <View style={styles.tableCol}></View>
        <View style={styles.tableCol}></View>
        <View style={styles.tableCol}></View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{`${sacks} bultos`}</Text>
        </View>
      </View>
      {/* Row */}
      <View style={styles.tableRow}>
        <View style={styles.tableCol}></View>
        <View style={styles.tableCol}></View>
        <View style={styles.tableCol}>
          <Text style={styles.tableTitle}>Total:</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{`${weight} Kg`}</Text>
        </View>
      </View>
      {/* Row */}
      <View style={styles.tableRow}>
        <View style={styles.tableCol}></View>
        <View style={styles.tableCol}></View>
        <View style={styles.tableCol}></View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{`$${total} mxn`}</Text>
        </View>
      </View>
    </View>
  );
}
