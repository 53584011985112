import React, { Fragment, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { db } from '../../firebase';
// Ant Design
import { Typography } from 'antd';
// Components
import AddButton from '../AddButton';
import Batch from './Batch';
import Breadcrumbs from '../Breadcrumbs';
import Loader from '../Loader';
// Context
import AppContext from '../../context/app/AppContext';

export default function Batches() {
  const history = useHistory();
  const appContext = useContext(AppContext);
  useEffect(() => {
    db.collection('batches')
      .where('location', '==', appContext.location)
      .orderBy('warehouseTitle')
      .orderBy('title')
      .onSnapshot((querySnapshot) => {
        const batches = [];
        querySnapshot.forEach((doc) => {
          batches.push({ ...doc.data(), id: doc.id });
        });
        appContext.batchesSet(batches);
      });
    // eslint-disable-next-line
  }, []);
  return (
    <Fragment>
      <AddButton handleAdd={() => history.push('/lotes/agregar')} />
      <Breadcrumbs data={[{ label: 'Lotes', path: '/lotes' }]} title="Lotes" />
      {appContext.batches ? (
        appContext.batches.map((data, i) => <Batch data={data} key={i} />)
      ) : (
        <Loader />
      )}
      {appContext.batches && appContext.batches.length === 0 && (
        <Typography.Paragraph>
          {' '}
          No has agregado ningun lote
        </Typography.Paragraph>
      )}
    </Fragment>
  );
}
