import React, { useReducer } from 'react';
import { db, firebase } from '../../firebase';
import { message } from 'antd';
// Context
import AppContext from './AppContext';
import AppReducer from './AppReducer';
import {
  BATCHES_SET,
  ENTRIES_SET,
  EXITS_SET,
  LOADING_SET,
  LOADING_UNSET,
  LOCATION_SET,
  SIGN_OUT,
  TRANSFERS_INCOMING_SET,
  TRANSFERS_OUTGOING_SET,
  USER_SET,
  WAREHOUSES_SET,
} from '../types';

const fbFunction = (f) => firebase.functions().httpsCallable(f);
const httpsBatchAdd = fbFunction('httpsBatchAdd');
const httpsBatchDelete = fbFunction('httpsBatchDelete');
const httpsBatchUpdate = fbFunction('httpsBatchUpdate');
const httpsBatchesGet = fbFunction('httpsBatchesGet');
const httpsEntriesGet = fbFunction('httpsEntriesGet');
const httpsEntryDisable = fbFunction('httpsEntryDisable');
const httpsEntrySave = fbFunction('httpsEntrySave');
const httpsExitAdd = fbFunction('httpsExitAdd');
const httpsExitDisable = fbFunction('httpsExitDisable');
const httpsExitUpdate = fbFunction('httpsExitUpdate');
const httpsExitsGet = fbFunction('httpsExitsGet');
const httpsTransferAdd = fbFunction('httpsTransferAdd');
const httpsTransferDelete = fbFunction('httpsTransferDelete');
const httpsTransferReceive = fbFunction('httpsTransferReceive');
const httpsTransfersIncomingGet = fbFunction('httpsTransfersIncomingGet');
const httpsTransfersOutgoingGet = fbFunction('httpsTransfersOutgoingGet');
const httpsUserAdd = fbFunction('httpsUserAdd');
const httpsUserDelete = fbFunction('httpsUserDelete');
const httpsWarehouseAdd = fbFunction('httpsWarehouseAdd');
const httpsWarehouseDelete = fbFunction('httpsWarehouseDelete');
const httpsWarehousesGet = fbFunction('httpsWarehousesGet');
// const httpsUserUpdate = fbFunction('httpsUserUpdate');
// httpsUserUpdate({
//   user: '',
//   properties: {
//     displayName: '',
//   },
// }).then((res) => console.log(res));

const AppState = (props) => {
  const initialState = {
    batches: null,
    loading: false,
    location: null,
    locations: ['Matriz', 'Nutdel', 'Sexta', 'ODI'],
    entries: null,
    exits: null,
    transfersIncoming: [],
    transfersOutgoing: [],
    user: null,
    warehouses: [],
  };

  const [state, dispatch] = useReducer(AppReducer, initialState);

  // Add Batch
  const batchAdd = async (description, title, warehouse) => {
    loadingSet();
    await httpsBatchAdd({
      description,
      location: state.location,
      sacks: 0,
      title,
      warehouse: warehouse.value,
      warehouseTitle: warehouse.label,
      weight: 0,
    });
    loadingUnset();
  };
  // Delete Batch
  const batchDelete = async (id) => {
    loadingSet();
    await httpsBatchDelete({ id, location: state.location });
    loadingUnset();
  };
  // Update Batch
  const batchUpdate = async (batch, id) => {
    loadingSet();
    await httpsBatchUpdate({ ...batch, id });
    loadingUnset();
  };
  // Get Batches
  const batchesGet = async () => {
    loadingSet();
    const result = await httpsBatchesGet({ location: state.location });
    batchesSet(result.data);
  };
  // Get Batches
  const batchesSet = (batches) => {
    dispatch({
      type: BATCHES_SET,
      payload: batches,
    });
  };
  // End Dryer
  const dryerEnd = async (dryer) => {
    loadingSet();
    await db.doc(`dryers/${dryer.id}`).update(dryer);
    loadingUnset();
  };
  // Start Dryer
  const dryerStart = async (dryer) => {
    loadingSet();
    await db.collection('dryers').add({ ...dryer, location: state.location });
    loadingUnset();
  };
  // Set Loading
  const loadingSet = () => dispatch({ type: LOADING_SET });
  // Set Loading
  const loadingUnset = () => dispatch({ type: LOADING_UNSET });
  // Set Location
  const locationSet = (location) => {
    dispatch({ type: LOCATION_SET, payload: location });
  };
  // Get Entries
  const entriesGet = async ({ keywords, range }) => {
    loadingSet();
    const res = await httpsEntriesGet({
      keywords,
      location: state.location,
      range,
    });
    entriesSet(res.data);
    loadingUnset();
  };
  // Set Entries
  const entriesSet = (entries) => {
    dispatch({
      type: ENTRIES_SET,
      payload: entries,
    });
  };
  // Disable Entry
  const entryDisable = async (entry) => {
    loadingSet();
    await httpsEntryDisable({ entry });
    message.success('Entrada cancelada');
    loadingUnset();
  };
  // Add Entry
  const entrySave = async (entry, uid) => {
    loadingSet();
    await httpsEntrySave({ entry, location: state.location, uid });
    message.success('Entrada guardada');
    loadingUnset();
  };
  // Add Exit
  const exitAdd = async (exit) => {
    loadingSet();
    await httpsExitAdd({ exit, location: state.location });
    message.success('Salida guardada');
    loadingUnset();
  };
  // Disable Exit
  const exitDisable = async (exit) => {
    loadingSet();
    await httpsExitDisable({ exit });
    message.success('Salida cancelada');
    loadingUnset();
  };
  // Update Exit
  const exitUpdate = async (exit, uid) => {
    loadingSet();
    await httpsExitUpdate({ exit, uid });
    message.success('Salida guardada');
    loadingUnset();
  };
  // Get Exits
  const exitsGet = async ({ keywords, range }) => {
    loadingSet();
    const res = await httpsExitsGet({
      keywords,
      location: state.location,
      range,
    });
    exitsSet(res.data);
    loadingUnset();
  };
  // Set Exits
  const exitsSet = (exits) => {
    dispatch({
      type: EXITS_SET,
      payload: exits,
    });
  };
  // Add transfer
  const transferAdd = async (transfer) => {
    loadingSet();
    await httpsTransferAdd({ transfer, location: state.location });
    await transfersIncomingGet();
    await transfersOutgoingGet();
    message.success('Transferencia enviada');
    loadingUnset();
  };
  // Delete Transfer
  const transferDelete = async (transfer) => {
    loadingSet();
    await httpsTransferDelete({ transfer });
    await transfersIncomingGet();
    await transfersOutgoingGet();
    message.success('Transferencia cancelada');
    loadingUnset();
  };
  // Receive Transfer
  const transferReceive = async ({ destination, transfer }) => {
    loadingSet();
    await httpsTransferReceive({ destination, transfer });
    await transfersIncomingGet();
    await transfersOutgoingGet();
    message.success('Transferencia recibida');
    loadingUnset();
  };

  // Get Incoming Transfers
  const transfersIncomingGet = async () => {
    loadingSet();
    const res = await httpsTransfersIncomingGet({ location: state.location });
    transfersIncomingSet(res.data);
    loadingUnset();
  };
  // Get outgoing transfers
  const transfersOutgoingGet = async () => {
    loadingSet();
    const res = await httpsTransfersOutgoingGet({ location: state.location });
    transfersOutgoingSet(res.data);
    loadingUnset();
  };

  // Set Incoming Transfers
  const transfersIncomingSet = (payload) => {
    dispatch({
      type: TRANSFERS_INCOMING_SET,
      payload,
    });
  };
  // Set outgoing transfers
  const transfersOutgoingSet = (payload) => {
    dispatch({
      type: TRANSFERS_OUTGOING_SET,
      payload,
    });
  };
  // Add User
  async function userAdd(user) {
    loadingSet();
    const { data } = await httpsUserAdd({ location: state.location, user });
    data.error && message.error(data.error.errorInfo.message);
    loadingUnset();
  }
  // Delete User
  async function userDelete(uid) {
    loadingSet();
    httpsUserDelete({ uid });
    loadingUnset();
  }
  // Set User
  const userSet = async (user) => {
    let payload = null;
    if (user) {
      const doc = await db.doc(`users/${user.uid}`).get();
      payload = {
        ...doc.data(),
        uid: user.uid,
      };
    }
    dispatch({
      type: USER_SET,
      payload,
    });
  };
  // Sign In
  const userSignIn = async (email, password) => {
    loadingSet();
    await firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch((error) => alert(error));
    loadingUnset();
  };
  // Sign Out
  const userSignOut = async (transfer) => {
    loadingSet();
    await firebase.auth().signOut();
    dispatch({
      type: SIGN_OUT,
      payload: initialState,
    });
    loadingUnset();
  };
  // Add Warehouse
  const warehouseAdd = async (title) => {
    loadingSet();
    await httpsWarehouseAdd({
      location: state.location,
      sacks: 0,
      title,
      weight: 0,
    });
    warehousesGet();
    loadingUnset();
  };
  // Delete Warehouse
  const warehouseDelete = async (warehouse) => {
    loadingSet();
    await httpsWarehouseDelete({ warehouse });
    warehousesGet();
    loadingUnset();
  };
  // Get Warehouses
  const warehousesGet = async () => {
    loadingSet();
    const res = await httpsWarehousesGet({ location: state.location });
    warehousesSet(res.data);
    loadingUnset();
  };
  // Set Warehouses
  const warehousesSet = (payload) => {
    dispatch({
      type: WAREHOUSES_SET,
      payload,
    });
  };
  return (
    <AppContext.Provider
      value={{
        batches: state.batches,
        loading: state.loading,
        location: state.location,
        locations: state.locations,
        entries: state.entries,
        exits: state.exits,
        transfersIncoming: state.transfersIncoming,
        transfersOutgoing: state.transfersOutgoing,
        user: state.user,
        warehouses: state.warehouses,
        batchAdd,
        batchUpdate,
        batchDelete,
        batchesGet,
        batchesSet,
        dryerEnd,
        dryerStart,
        entriesGet,
        entriesSet,
        entryDisable,
        entrySave,
        exitAdd,
        exitDisable,
        exitUpdate,
        exitsGet,
        exitsSet,
        locationSet,
        transferAdd,
        transferDelete,
        transferReceive,
        transfersIncomingGet,
        transfersOutgoingGet,
        transfersIncomingSet,
        transfersOutgoingSet,
        userAdd,
        userDelete,
        userSet,
        userSignIn,
        userSignOut,
        warehouseAdd,
        warehouseDelete,
        warehousesGet,
        warehousesSet,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppState;
