import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { db } from '../../firebase';
// Ant Design
import { Table, Typography } from 'antd';
// Components
import AddButton from '../AddButton';
import Breadcrumbs from '../Breadcrumbs';
import UserDelete from './UserDelete';
// import Dryer from './Dryer';
// Context
import AppContext from '../../context/app/AppContext';

const { Title } = Typography;
const columns = [
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Correo',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Acción',
    dataIndex: 'uid',
    key: 'uid',
    render: UserDelete,
  },
];

export default function Users() {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const [users, setUsers] = useState([]);
  useEffect(() => {
    db.collection('users')
      .orderBy('name', 'desc')
      .where('locations', 'array-contains', appContext.location)
      .onSnapshot((querySnapshot) => {
        const data = [];
        querySnapshot.forEach((doc) => {
          data.push({ ...doc.data(), uid: doc.id, key: doc.id });
        });
        setUsers(data);
      });
    // eslint-disable-next-line
  }, []);
  return (
    <Fragment>
      <Breadcrumbs
        data={[{ label: 'Usuarios', path: '/usuarios' }]}
        title="Usuarios"
      />
      <Title level={5}>Transferencias Entrantes</Title>
      <Table
        dataSource={users}
        columns={columns}
        loading={appContext.loading}
      />
      <AddButton handleAdd={() => history.push('/usuarios/agregar')} />
    </Fragment>
  );
}
