import {
  BATCHES_SET,
  ENTRIES_SET,
  EXITS_SET,
  LOADING_SET,
  LOADING_UNSET,
  LOCATION_SET,
  SIGN_OUT,
  TRANSFERS_INCOMING_SET,
  TRANSFERS_OUTGOING_SET,
  USER_SET,
  WAREHOUSES_SET,
} from '../types';

export default function appReducer(state, action) {
  switch (action.type) {
    case BATCHES_SET:
      return {
        ...state,
        batches: action.payload,
        loading: false,
      };
    case LOCATION_SET:
      return {
        ...state,
        location: action.payload,
      };
    case LOADING_SET:
      return {
        ...state,
        loading: true,
      };
    case LOADING_UNSET:
      return {
        ...state,
        loading: false,
      };
    case ENTRIES_SET:
      return {
        ...state,
        loading: false,
        entries: action.payload,
      };
    case EXITS_SET:
      return {
        ...state,
        loading: false,
        exits: action.payload,
      };
    case SIGN_OUT:
      return action.payload;
    case TRANSFERS_INCOMING_SET:
      return {
        ...state,
        transfersIncoming: action.payload,
      };
    case TRANSFERS_OUTGOING_SET:
      return {
        ...state,
        transfersOutgoing: action.payload,
      };
    case USER_SET:
      return {
        ...state,
        user: action.payload,
      };
    case WAREHOUSES_SET:
      return {
        ...state,
        warehouses: action.payload,
      };
    default:
      return state;
  }
}
