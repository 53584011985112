import React, { useState } from 'react';
// Material UI
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiButtonBase-root': {
      marginBottom: theme.spacing(2),
    },
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(2),
      width: '100%',
    },
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(2),
    },
  },
}));

const getTime = () => {
  const d = new Date();
  const hours = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours();
  const minutes = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes();
  return `${hours}:${minutes}`;
};

export default function Process({ handleClose, handleSave }) {
  const classes = useStyles();
  const [endTime, setET] = useState(getTime);
  const [startTime, setST] = useState(getTime);
  const [process, setProcess] = useState('Aire');
  const handleClick = () => {
    handleSave({
      endTime,
      startTime,
      process,
    });
    setET(getTime);
    setST(getTime);
    setProcess('Aire');
    handleClose();
  };
  return (
    <div className={classes.root}>
      <form noValidate autoComplete="off">
        <TextField
          fullWidth
          label="Hora de Inicio"
          onChange={(e) => setST(e.target.value)}
          type="time-picker"
          value={startTime}
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Hora de Termino"
          onChange={(e) => setET(e.target.value)}
          type="time-picker"
          value={endTime}
          variant="outlined"
        />
        <FormControl variant="outlined">
          <InputLabel id="select-label">Proceso</InputLabel>
          <Select
            labelId="select-label"
            id="select-outlined"
            value={process}
            onChange={(e) => setProcess(e.target.value)}
            label="Proceso"
          >
            <MenuItem value="Aire">Aire</MenuItem>
            <MenuItem value="Fuego">Fuego</MenuItem>
          </Select>
        </FormControl>
      </form>
      <Button
        color="primary"
        fullWidth
        onClick={() => handleClick()}
        variant="outlined"
      >
        Guardar
      </Button>
    </div>
  );
}
