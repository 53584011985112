export const BATCHES_SET = 'BATCHES_SET';
export const ENTRIES_SET = 'ENTRIES_SET';
export const EXITS_SET = 'EXITS_SET';
export const LOADING_SET = 'LOADING_SET';
export const LOADING_UNSET = 'LOADING_UNSET';
export const LOCATION_SET = 'LOCATION_SET';
export const SIGN_OUT = 'SIGN_OUT';
export const TRANSFERS_INCOMING_SET = 'TRANSFERS_INCOMING_SET';
export const TRANSFERS_OUTGOING_SET = 'TRANSFERS_OUTGOING_SET';
export const USER_SET = 'USER_SET';
export const WAREHOUSES_SET = 'WAREHOUSES_SET';
