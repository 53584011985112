import React from 'react';
// Ant Design
import { Button } from 'antd';
// Styles
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1rem',
  },
  secondary: {
    marginRight: '1rem',
  },
}));

export default function Actions({
  disabled,
  handleCancel,
  handleSave,
  loading,
}) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {handleCancel && (
        <Button
          className={classes.secondary}
          onClick={handleCancel}
          type="text"
        >
          CANCELAR
        </Button>
      )}
      <Button
        disabled={disabled}
        loading={loading}
        onClick={handleSave}
        type="primary"
      >
        GUARDAR
      </Button>
    </div>
  );
}
