import React, { Fragment, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
// Components
import Actions from '../Actions';
import Breadcrumbs from '../Breadcrumbs';
import { SelectWarehouse } from '../Warehouses';
// Context
import AppContext from '../../context/app/AppContext';
// Material UI
import TextField from '@material-ui/core/TextField';
// Styles
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  form: {
    '& .MuiTextField-root': {
      marginBottom: '1rem',
    },
  },
}));

export function AddBatch() {
  const appContext = useContext(AppContext);
  const classes = useStyles();
  const history = useHistory();
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('');
  const [warehouse, setWarehouse] = useState({ label: '', value: '' });
  const handleSave = async () => {
    if (title && warehouse) {
      await appContext.batchAdd(description, title, warehouse);
      setDescription('');
      setTitle('');
      history.push('/lotes');
    } else {
      alert('Debes escribir un titulo y seleccionar una bodega');
    }
  };
  return (
    <Fragment>
      <Breadcrumbs
        data={[{ label: 'Lotes', path: '/lotes' }, { label: 'Agregar' }]}
        title="Agregar Lote"
      />
      <form noValidate autoComplete="off" className={classes.form}>
        <TextField
          fullWidth
          label="Titulo"
          onChange={(e) => setTitle(e.target.value)}
          value={title}
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Descripción"
          onChange={(e) => setDescription(e.target.value)}
          value={description}
          variant="outlined"
        />
        <SelectWarehouse setValue={setWarehouse} value={warehouse} />
      </form>
      <Actions
        handleCancel={() => history.push('/lotes')}
        handleSave={handleSave}
        loading={appContext.loading}
      />
    </Fragment>
  );
}
