import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
// Ant Design
import { Button, Card, Descriptions, Popconfirm, Tag, Typography } from 'antd';
import { EditOutlined, PrinterOutlined, StopOutlined } from '@ant-design/icons';
// Context
import AppContext from '../../context/app/AppContext';
// Styles
import { createUseStyles } from 'react-jss';

const { Title } = Typography;

const useStyles = createUseStyles({
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
});

export default function ExitBrief({ exit }) {
  const { exitDisable, loading } = useContext(AppContext);
  const classes = useStyles();
  let history = useHistory();

  const { disabled, id, transfer, uid } = exit;
  const description = [
    { label: 'Destino', value: exit.destination },
    { label: 'Conductor', value: exit.driver },
    { label: 'Bultos', value: exit.sacks },
    { label: 'Peso', value: exit.weight },
  ];

  return (
    <Card>
      <div className={classes.head}>
        <div>
          <Title level={5}>{id}</Title>
          <Tag color="error" visible={disabled}>
            Cancelada
          </Tag>
          <Tag color="blue" visible={transfer}>
            Transferencia
          </Tag>
        </div>
        <div>
          <Button
            disabled={disabled || loading}
            icon={<EditOutlined />}
            onClick={() => history.push(`/salidas/${uid}`)}
            type="text"
          />
          <Button
            disabled={disabled || loading}
            icon={<PrinterOutlined />}
            onClick={() => history.push(`/salidas/imprimir/${uid}`)}
            type="text"
          />
          <Popconfirm
            title={`¿Deseas cancelar la salida ${id}?`}
            onConfirm={() => exitDisable(exit)}
            okText="Si"
            cancelText="No"
          >
            <Button
              disabled={disabled || loading}
              icon={<StopOutlined />}
              type="text"
            />
          </Popconfirm>
        </div>
      </div>
      <Descriptions>
        {description.map(
          ({ label, value }, i) =>
            value && (
              <Descriptions.Item label={label} key={i}>
                {value}
              </Descriptions.Item>
            )
        )}
      </Descriptions>
    </Card>
  );
}
