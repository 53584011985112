import React, { useContext, useEffect } from 'react';
// Context
import AppContext from '../../context/app/AppContext';
// Material UI
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

export const SelectBatch = ({ disabled, value, setValue }) => {
  const appContext = useContext(AppContext);
  useEffect(() => {
    appContext.batchesGet();
    // eslint-disable-next-line
  }, []);
  return (
    <Autocomplete
      disabled={disabled}
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      options={appContext.batches || []}
      getOptionLabel={(option) => option.title || ''}
      style={{ width: '100%' }}
      renderInput={(params) => (
        <TextField {...params} label="Lote" variant="outlined" />
      )}
    />
  );
};
