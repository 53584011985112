import React, { useContext } from 'react';
// Ant Design
import { Button, Popconfirm, message } from 'antd';
// Context
import AppContext from '../../context/app/AppContext';

export default function UserDelete(uid) {
  const { loading, userDelete } = useContext(AppContext);
  async function onConfirm() {
    await userDelete(uid);
    message.success('Usuario borrado');
  }
  return (
    <Popconfirm
      title="¿Quieres borrar este usuario?"
      onConfirm={onConfirm}
      // onCancel={cancel}
      okText="Borrar"
      cancelText="Cancelar"
    >
      <Button loading={loading} type="link">
        Borrar
      </Button>
    </Popconfirm>
  );
}
