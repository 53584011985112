import React, { Fragment, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
// Components
import Actions from '../Actions';
import Breadcrumbs from '../Breadcrumbs';
// Context
import AppContext from '../../context/app/AppContext';
// Antd
import { message, Input, Space } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

export function UserAdd() {
  const { loading, userAdd } = useContext(AppContext);
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  function handleSave() {
    if (email && confirmation && name && password) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(String(email).toLowerCase())) {
        if (password === confirmation) {
          userAdd({ email, name, password });
          setEmail('');
          setConfirmation('');
          setName('');
          setPassword('');
          history.push('usuarios');
        } else {
          message.error('Las contraseñas no coinciden');
        }
      } else {
        message.error('El correo no es valido');
      }
    } else {
      message.error('Llena los campos correctamente');
    }
  }
  return (
    <Fragment>
      <Breadcrumbs
        data={[{ label: 'Usuarios', path: '/usuarios' }, { label: 'Agregar' }]}
        title="Agregar Usuario"
      />
      <Space direction="vertical">
        <Input
          placeholder="Nombre"
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
        <Input
          placeholder="Correo"
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          value={email}
        />
        <Input.Password
          placeholder="Contraseña"
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
        <Input.Password
          placeholder="Confirmar Contraseña"
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          onChange={(e) => setConfirmation(e.target.value)}
          value={confirmation}
        />
        <Actions loading={loading} handleSave={handleSave} />
      </Space>
    </Fragment>
  );
}
