import React, { Fragment, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// Ant Design
import { Table, Typography } from 'antd';
// Components
import AddButton from '../AddButton';
import Breadcrumbs from '../Breadcrumbs';
import TransferDelete from './TransferDelete';
import TransferReceive from './TransferReceive';
// Context
import AppContext from '../../context/app/AppContext';
const { Title } = Typography;
const columns = [
  {
    title: 'Peso',
    dataIndex: 'weightSent',
    key: 'weightSent',
  },
  {
    title: 'Variedad',
    dataIndex: 'variety',
    key: 'variety',
  },
  {
    title: 'Receptor',
    dataIndex: 'receiver',
    key: 'receiver',
  },
  {
    title: 'Accion',
    dataIndex: 'type',
    key: 'type',
    render: (type, transfer) =>
      type === 'outgoing' ? (
        <TransferDelete transfer={transfer} />
      ) : (
        <TransferReceive transfer={transfer} />
      ),
  },
];

export default function Transfers() {
  const appContext = useContext(AppContext);
  const history = useHistory();
  useEffect(() => {
    appContext.transfersIncomingGet();
    appContext.transfersOutgoingGet();
    // eslint-disable-next-line
  }, []);
  const { transfersIncoming, transfersOutgoing } = appContext;
  return (
    <Fragment>
      <Breadcrumbs
        data={[{ label: 'Transferencias', path: '/transferencias' }]}
        title="Transferencias"
      />
      <Title level={5}>Transferencias Entrantes</Title>
      <Table
        dataSource={transfersIncoming}
        columns={columns}
        loading={appContext.loading}
      />

      <Title level={5}>Transferencias Salientes</Title>
      <Table
        dataSource={transfersOutgoing}
        columns={columns}
        loading={appContext.loading}
      />
      <AddButton handleAdd={() => history.push('/transferencias/agregar')} />
    </Fragment>
  );
}
