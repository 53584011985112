import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
// Ant Design
import { DatePicker, Space } from 'antd';
// Components
import AddButton from '../AddButton';
import Breadcrumbs from '../Breadcrumbs';
import Loader from '../Loader';
import ExitBrief from './ExitBrief';
import Search from '../Search';
// Context
import AppContext from '../../context/app/AppContext';

const { RangePicker } = DatePicker;

export default function Exits() {
  const { exits, exitsGet } = useContext(AppContext);
  const [keywords, setKeywords] = useState([]);
  const [range, setRange] = useState(null);
  const history = useHistory();
  useEffect(() => {
    exitsGet({});
    // eslint-disable-next-line
  }, []);
  function getDates(dates) {
    if (dates) {
      return [
        moment(dates[0]).format('YYYY-MM-DD'),
        moment(dates[1]).format('YYYY-MM-DD'),
      ];
    } else {
      return null;
    }
  }
  function onRangePick(dates) {
    setRange(dates);
    exitsGet({ keywords, range: getDates(dates) });
  }
  function onSearch(search) {
    exitsGet({ keywords: search, range: getDates(range) });
  }
  return (
    <Fragment>
      <Breadcrumbs
        data={[{ label: 'Salidas', path: '/salidas' }]}
        title="Salidas"
      />
      <Space direction="vertical">
        <Search
          keywords={keywords}
          setKeywords={setKeywords}
          onSearch={onSearch}
        />
        <RangePicker onChange={onRangePick} value={range} />
        {exits ? (
          exits.map((exit, i) => <ExitBrief key={i} exit={exit} />)
        ) : (
          <Loader />
        )}
      </Space>
      <AddButton handleAdd={() => history.push('/salidas/agregar')} />
    </Fragment>
  );
}
