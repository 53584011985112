import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
// Context
import AppContext from '../../context/app/AppContext';
// Ant Design
import { Card, Collapse, Dropdown, Menu, Typography } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
// Styles
import { createUseStyles } from 'react-jss';

const { Panel } = Collapse;
const { Item } = Menu;
const { Paragraph, Text, Title } = Typography;

const useStyles = createUseStyles({
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
});

export default function Batch({ data }) {
  const classes = useStyles();
  const history = useHistory();
  const { description, id, nuts, sacks, title, warehouseTitle, weight } = data;
  const { batchDelete } = useContext(AppContext);
  const menu = (
    <Menu>
      <Item onClick={() => history.push(`/lotes/editar/${id}`)}>Editar</Item>
      <Item onClick={() => batchDelete(id)}>Borrar</Item>
    </Menu>
  );
  return (
    <Card>
      <div className={classes.head}>
        <div>
          <Title level={5}>
            {warehouseTitle ? `${warehouseTitle} ${title}` : title}
          </Title>
          <Text level={5}>{description}</Text>
        </div>
        <Dropdown overlay={menu}>
          <MoreOutlined />
        </Dropdown>
      </div>
      <Collapse expandIconPosition="right">
        <Panel header={`Bultos: ${sacks}, Peso: ${weight}`}>
          {nuts &&
            Object.values(nuts).map(
              (n, i) =>
                (n.weight || n.sacks) && (
                  <Paragraph key={i}>
                    {`${n.label}: ${n.weight ? `${n.weight} Kg, ` : ''}${
                      n.sacks ? `${n.sacks} Bultos` : ''
                    }`}
                  </Paragraph>
                )
            )}
        </Panel>
      </Collapse>
    </Card>
  );
}
